import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getCookie, getBoot, setCookie } from "../../utils";
import Login from "../modals/Login.jsx";
import Registration from "../modals/Registration.jsx";
import "./navigation.css";
import LogoBlanco from "../../assets/images/LogoBlanco.png";
import IconoLenguaje from "../../assets/images/icons/Lenguaje.svg";
import IconoMenu from "../../assets/images/icons/menu.png";

class Navigation extends Component {
  state = {
    boot: getBoot()[getCookie("Orolang")],
    currentlang: "",
  };
  showLangMenu = () => {
    let x = document.getElementById("selectorLenguaje");
    if (x.style.display === "none" || x.style.display === "") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  };
  showMenuMovil = () => {
    const menuMovil = document.getElementById("menuMovil");
    const enlaces = document.querySelectorAll("#menuMovil li");
    menuMovil.classList.toggle("abierto");

    enlaces.forEach((link) => {
      link.classList.toggle("fade");
    });
  };
  fillActiveLang = () => {
    let lang = getCookie("Orolang");
    if (lang === "es") {
      let activelang = document.getElementById("idiomaEspanol");
      let unactivelang = document.getElementById("idiomaIngles");
      let unactivelang1 = document.getElementById("idiomaPortugues");
      activelang.classList.add("botonActivo");
      unactivelang.classList.remove("botonActivo");
      unactivelang1.classList.remove("botonActivo");
    } else if (lang === "pt") {
      let activelang = document.getElementById("idiomaPortugues");
      let unactivelang = document.getElementById("idiomaIngles");
      let unactivelang1 = document.getElementById("idiomaEspanol");
      activelang.classList.add("botonActivo");
      unactivelang.classList.remove("botonActivo");
      unactivelang1.classList.remove("botonActivo");
    } else {
      let unactivelang = document.getElementById("idiomaEspanol");
      let activelang = document.getElementById("idiomaIngles");
      let unactivelang1 = document.getElementById("idiomaPortugues");
      activelang.classList.add("botonActivo");
      unactivelang.classList.remove("botonActivo");
      unactivelang1.classList.remove("botonActivo");
    }
  };
  changeLang = (lang) => {
    setCookie("Orolang", lang, 20);
    window.location.reload();
  };

  componentDidMount() {
    this.fillActiveLang();
    this.getActivePage();
  }

  getActivePage = () => {
    if (this.props.activo === "legales" || this.props.activo === undefined) {
    } else {
      let active = document.getElementById(this.props.activo);
      active.classList.add("enlaceActivo");
    }
  };
  render() {
    return (
      <>
        <div className="contenedor">
          <nav>
            <Link to="/">
              <img
                src={LogoBlanco}
                alt="Logo Blanco"
                className="logoNavegacion"
              />
            </Link>
            <div className="enlacesNavegacion">
              <div className="enlacesEscritorio">
                <ul>
                  <li id="academia">
                    <Link to="/Academia"> {this.state.boot.nav.academia}</Link>
                  </li>
                  <li id="plataforma">
                    <Link to="/Plataforma">
                      {this.state.boot.nav.plataforma}
                    </Link>
                  </li>
                  <li id="acerca">
                    <Link to="/Acerca">{this.state.boot.nav.acerca}</Link>
                  </li>
                  <li id="soporte">
                    <a href="http://support.orotrader.com/" target="_new">
                      {this.state.boot.nav.soporte}
                    </a>
                  </li>
                </ul>
                <Login />
                <Registration />
              </div>
              <div className="ajustesLenguaje">
                <button
                  className="botonMostrarLenguaje"
                  onClick={(e) => this.showLangMenu()}
                >
                  <img
                    src={IconoLenguaje}
                    alt="Icono Lenguaje"
                    className="iconoLenguaje"
                  />
                </button>
                <div className="selectorLenguaje" id="selectorLenguaje">
                  <button
                    onClick={(e) => this.changeLang("es")}
                    id="idiomaEspanol"
                  >
                    Español
                  </button>
                  <button
                    onClick={(e) => this.changeLang("en")}
                    id="idiomaIngles"
                  >
                    English
                  </button>
                  <button
                    onClick={(e) => this.changeLang("pt")}
                    id="idiomaPortugues"
                  >
                    Portuguese
                  </button>
                </div>
                <button
                  onClick={(e) => this.showMenuMovil()}
                  className="menuMovil"
                >
                  <img src={IconoMenu} alt="Menu" />
                </button>
              </div>
            </div>
          </nav>
        </div>
        <div className="enlacesMoviles" id="menuMovil">
          <li>
            <Link to="/">{this.state.boot.nav.inicio}</Link>
          </li>
          <li id="academiaMovil">
            <Link to="/Academia">{this.state.boot.nav.academia}</Link>
          </li>
          <li id="plataformaMovil">
            <Link to="/Plataforma">{this.state.boot.nav.plataforma}</Link>
          </li>
          <li id="acercaMovil">
            <Link to="/Acerca">{this.state.boot.nav.acerca}</Link>
          </li>
          <li id="soporte">
            <a href="http://support.orotrader.com/" target="_new">
              {this.state.boot.nav.soporte}
            </a>
          </li>
          <li>
            <div className="contenedorBotonMovil">
              <Login />
              <Registration />
            </div>
          </li>
        </div>
      </>
    );
  }
}

export default Navigation;
