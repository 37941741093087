import React, { useEffect, useRef, useState } from "react";
import { Chart, registerables } from "chart.js";
import DataTable from "react-data-table-component";
// import { getCookie, getBoot } from "../../utils";
// import {
//   Chart, LineElement, PointElement, LineController, CategoryScale, LinearScale,
//   LogarithmicScale, TimeScale, TimeSeriesScale, Decimation, Filler, Legend, Title, Tooltip
// } from 'chart.js';

import "./simulator.scss";

import Navigation from "../../components/navigation/navigation.jsx";
import Footer from "../../components/footer/footer.jsx";
import swal from "sweetalert";

export default function Simulator(props) {
  // const boot = getBoot()[getCookie("Orolang")];
  const CanvasRef = useRef(null);
  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState();
  const [filters, setFilters] = useState({ systems: [] });
  const [amounts, setAmounts] = useState([]);
  const [periods, setPeriods] = useState([]);
  const simulatorSystem = useRef(null);
  const settingsRef = useRef(null);

  const hadleSimulation = (idSystem) => (event) => {
    let systems = [...filters.systems];
    if (systems.indexOf(idSystem) === -1) {
      systems.push(idSystem);
    } else {
      systems = systems.filter((sys) => sys !== idSystem);
    }
    setFilters({ ...filters, systems });
  };

  const columns = [
    {
      name: "Rank",
      selector: "rank",
      sortable: true,
      center: true,
      width: "70px",
      cell: ({ rank }) => (
        <span className="simulator__systems-row simulator__systems-row--bold">
          {rank}
        </span>
      )
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
      center: true,
      minWidth: "150px",
      cell: ({ name }) => (
        <p className="simulator__systems-row simulator__systems-row--underline">
          {name}
        </p>
      )
    },
    {
      name: "Week gain",
      selector: "weekgain",
      sortable: true,
      center: true,
      cell: ({ weekgain }) => (
        <p className="simulator__systems-row simulator__systems-row--green">
          {weekgain}
        </p>
      )
    },
    {
      name: "1 Month gain",
      selector: "onemonthgain",
      sortable: true,
      center: true,
      cell: ({ onemonthgain }) => (
        <p className="simulator__systems-row simulator__systems-row--green">
          {onemonthgain}
        </p>
      )
    },
    {
      name: "3 Months gain",
      selector: "threemonthsgain",
      sortable: true,
      center: true,
      cell: ({ threemonthsgain }) => (
        <p className="simulator__systems-row simulator__systems-row--green">
          {threemonthsgain}
        </p>
      )
    },
    {
      name: "Year gain",
      selector: "yeargain",
      sortable: true,
      center: true,
      cell: ({ yeargain }) => (
        <p className="simulator__systems-row simulator__systems-row--green">
          {yeargain}
        </p>
      )
    },
    {
      name: "Trading type",
      selector: "tradingtype",
      sortable: true,
      center: true
    },
    {
      name: "Subscribers",
      selector: "subscribers",
      sortable: true,
      center: true,
      cell: ({ subscribers }) => (
        <p className="simulator__systems-row">{subscribers}</p>
      )
    },
    {
      name: " ",
      selector: "id",
      sortable: false,
      center: true,
      width: "180px",
      cell: ({ id }) => (
        <button
          onClick={hadleSimulation(id)}
          className="simulator__systems-row simulator__systems-row--button"
          data-simulating={filters.systems.indexOf(id) >= 0}
        >
          {filters.systems.indexOf(id) === -1 ? "Simulate" : "Simulating"}
        </button>
      )
    }
  ];

  const onHandleSubmit = (event) => {
    event.preventDefault();
    if (filters.systems.length) {
      fetch("https://www.orotrader.com/api/getSimulatorChart.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
          // Authorization: "Bearer " + localStorage.getItem("sessionId"),
        },
        body: JSON.stringify(filters)
      })
        .then((res) => res.json())
        .then((response) => {
          if (!response.error) {
            setChartData(response.content);
            setTimeout(() => {
              document
                .getElementById("simulatorChart")
                .scrollIntoView({ behavior: "smooth" });
            }, 300);
          } else {
            swal("Upps", response.message, "error");
          }
        })
        .catch((error) => {
          swal("Upps", error, "error");
        });
    } else {
      swal(
        "Orotrader Simulator",
        "Please select at least 1 provider.",
        "warning"
      ).then(() => {
        if (simulatorSystem.current) {
          simulatorSystem.current.scrollIntoView({
            top: -100,
            behavior: "smooth"
          });
        }
      });
    }
  };

  const handleChange = (prop) => (event) => {
    setFilters({ ...filters, [prop]: event.target.value });
  };

  useEffect(() => {
    fetch("https://www.orotrader.com/api/getSimulatorChart.php", {
      headers: {}
    })
      .then((res) => res.json())
      .then((response) => {
        if (!response.error) {
          setData(response.content);
          setAmounts(response.catalogues.deposits);
          setPeriods(response.catalogues.times);
        } else {
          console.error(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    Chart.register(...registerables);
  }, []);

  useEffect(() => {
    const getLabel = (index) => {
      if (!index) return "";
      if (chartData) {
        const labels = chartData.labels
          .map((l, i) => ({ label: l, index: i }))
          .filter((l) => l.label !== "" && l.index <= index);
        return labels.length ? labels[labels.length - 1].label : "First days";
      }
    };
    if (CanvasRef.current) {
      const chartLine = new Chart(CanvasRef.current.getContext("2d"), {
        type: "line",
        data: chartData,
        options: {
          maintainAspectRatio: false,
          scales: {
            x: {
              ticks: {
                autoSkip: false
              },
              grid: {
                color: "#FFFFFF"
              }
            },
            y: {
              min: 0,
              beginAtZero: true,
              ticks: {
                callback: function (value) {
                  return parseFloat(((value / 1) * 100).toFixed(2)) + "%"; // convert it to percentage
                }
              },
              grid: {
                color: "#EEEEEE"
              }
            }
          },
          plugins: {
            tooltip: {
              callbacks: {
                title: function ([{ dataIndex, dataset }]) {
                  return dataIndex ? dataset.label : "";
                },
                label: function ({ dataIndex, label }) {
                  return getLabel(dataIndex);
                },
                afterLabel: function ({ dataIndex, parsed }) {
                  return dataIndex
                    ? parseFloat(((parsed.y / 1) * 100).toFixed(2)) + "%"
                    : "";
                }
              },
              backgroundColor: "#FFF",
              bodyColor: "#000",
              titleFont: 16,
              titleColor: "#d4af37",
              bodyFontSize: 14,
              borderColor: "#000",
              borderWidth: 1,
              displayColors: false
            }
          }
        }
      });
      return () => {
        chartLine.destroy();
      };
    }
  }, [chartData]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].intersectionRatio < 1) {
          settingsRef.current.classList.add(
            "simulator__settings-container--sticky"
          );
        } else {
          settingsRef.current.classList.remove(
            "simulator__settings-container--sticky"
          );
        }
      },
      {
        rootMargin: "-1px 0px 0px 0px",
        threshold: 1
      }
    );

    if (settingsRef.current) {
      observer.observe(settingsRef.current);
    }

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsRef.current]);

  return (
    <div>
      <Navigation />
      <div className="contenedor simulator__container">
        <h1 className="simulator__title">Simulator</h1>
        <p className="simulator__description">
          Use the simulator to test different AutoTrade setups. The simulator
          will process the data in real time for exact hypothetical performance.
        </p>
        <div ref={settingsRef} className="simulator__settings-container">
          <h2 className="simulator__settings-title">Settings</h2>
          <form className="simulator__form" onSubmit={(e) => onHandleSubmit(e)}>
            <label htmlFor="deposit" className="simulator__form-label">
              <p className="simulator__form-text">Deposit</p>
              <select
                name="deposit"
                className="simulator__form-input"
                onChange={handleChange("deposit")}
                required
              >
                <option value="">-</option>
                {amounts.map((deposit, i) => (
                  <option key={i} value={deposit}>
                    ${deposit}
                  </option>
                ))}
              </select>
            </label>
            <label htmlFor="time" className="simulator__form-label">
              <p className="simulator__form-text">Time</p>
              <select
                name="time"
                className="simulator__form-input"
                onChange={handleChange("time")}
                required
              >
                <option value="">-</option>
                {periods.map((time, i) => (
                  <option key={i} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </label>
            <button type="submit" className="simulator__form-button">
              Start simulation
            </button>
          </form>
        </div>
        <div className="simulator__chart" id="simulatorChart">
          <h2 className="simulator__chart-title">Chart</h2>
          <div className="simulator__chart-box">
            <h3 className="simulator__chart-box-title">Profit</h3>
            {chartData && (
              <canvas ref={CanvasRef} className="simulator__chartjs" />
            )}
            {!chartData && <p>No system selected</p>}
          </div>
        </div>
        <div className="simulator__systems" ref={simulatorSystem}>
          <h2 className="simulator__systems-title">Systems</h2>
          <div className="simulator__systems-box">
            <DataTable columns={columns} data={data} noHeader />
          </div>
        </div>
        <p className="simulator__disclamer">
          El trading conlleva un alto nivel de riesgo y puede no ser adecuado
          para todos los inversores. El alto grado de apalancamiento puede
          funcionar tanto en tu contra como a tu favor. Antes de decidir operar
          con divisas, acciones, metales, criptomonedas, fondos, materia prima y
          criptodivisas, debes considerar cuidadosamente tus objetivos de
          inversión, nivel de experiencia y apetito de riesgo. Los servicios
          financieros proporcionados por Orotrader conllevan un alto nivel de
          riesgo y existe la posibilidad de que puedas perder parte o la
          totalidad de tu inversión inicial, por lo tanto, no debes invertir el
          dinero que no puedas permitirte perder. Debes ser consciente de todos
          los riesgos asociados con el trading de divisas, binarias, criptos,
          acciones, fondos, materia prima entre otros, y buscar consejo de un
          asesor financiero acorde a tus necesidades. Comprenda que los riesgos
          son solo suyos, depende de usted evaluar si sus recursos financieros
          son adecuados y el nivel de riesgo que asume. Cualquier opinión,
          noticia, investigación, análisis, precios u otra información contenida
          en este sitio web se proporciona como comentario general del mercado,
          y no debe ser considerada un consejo de inversión. Orotrader no
          aceptará responsabilidad alguna por cualquier pérdida o daño, o cuando
          utilice Internet y otras instalaciones de comunicación de la red, uso
          de servicios como mt4 y mt5 o cualquier otro dispositivo electrónico,
          entre otros. Usted será responsable de cualquier pérdida de
          beneficios, que pueda surgir directa o indirectamente del uso o
          confianza en dicha información.
        </p>
      </div>
      <Footer />
    </div>
  );
}
