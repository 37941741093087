import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { getCookie, setCookie } from "./utils";

import Inicio from "./pages/home/home.jsx";
import Academics from "./pages/academics/academics.jsx";
import Platform from "./pages/platform/platform.jsx";
import About from "./pages/about/about.jsx";
import Legals from "./pages/legals/legals.jsx";
import Webinars from "./pages/webinars/webinars.jsx";
import Robots from "./pages/robots/robots.jsx";
import Simulator from "./pages/simulator/simulator.jsx";

const redirect = async () => {
  const request = await fetch("https://api.ipregistry.co/?key=ccu1zlzhgetyu5");
  const response = await request.json();
  if (response.location.country.code === "US" || response.location.country.code === "CA" || response.location.country.code === "IL") {
    window.location.replace("https://www.google.com/");
  }
}

class App extends Component {

  componentDidMount() {
    redirect();
  }

  render() {
    if (getCookie("Orolang") === null) {
      setCookie("Orolang", "es", 20);
      window.location.reload();
    }

    return (
      <BrowserRouter>
        <Route path="/" exact component={Inicio} />
        <Route path="/Academia" exact component={Academics} />
        <Route path="/Plataforma" exact component={Platform} />
        <Route path="/Acerca" exact component={About} />
        <Route path="/Politicas" exact component={Legals} />
        <Route path="/Webinars" exact component={Webinars} />
        <Route path="/Robots" exact component={Robots} />
        <Route path="/Simulator" exact component={Simulator} />
      </BrowserRouter>
    );
  }
}

export default App;
