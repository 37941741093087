import React, { Component } from "react";
import { getCookie, getBoot, getClientZoneApi } from "../../utils";
import { Modal } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import "././General.css";
import Logo from "../../assets/images/LogoNegro.png";

class SignIn extends Component {
  state = {
    abierto: window.location.hash === "#login",
    registro_abierto: window.location.hash === "#registro",
    boot: getBoot()[getCookie("Orolang")]
  };
  componentDidMount() {
    this.getCode();
  }

  getCode() {
    fetch("https://api.ipregistry.co/?key=ccu1zlzhgetyu5")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            code: result.location.country.code
          });
        },
        (error) => {
          this.setState({
            code: ""
          });
        }
      );
  }
  renderPaises = () => {
    let render = [];
    let pref = this.state.boot.countries;
    let code = this.state.code;
    if (this.state.code === "" || this.state.code === "US") {
      code = "PE";
    }
    if (pref !== undefined && pref !== []) {
      for (let i = 0; i < pref.length; i++) {
        if (pref[i].code !== code) {
          render.push(
            <option key={i} value={pref[i].code}>
              {pref[i].country}
            </option>
          );
        } else {
          render.push(
            <option key={i} value={pref[i].code} selected>
              {pref[i].country}
            </option>
          );
        }
      }
    }
    return render;
  };
  SignUp = (e) => {
    e.preventDefault();
    let f_country = document.getElementById("pais").value;
    let phoneCountryCode = "";
    document.getElementById("btnSignUp").value = "Loading...";
    document.getElementById("btnSignUp").disabled = true;
    fetch(`https://www.orotrader.com/api/restcountry.php?country=${f_country}`)
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        phoneCountryCode = "+" + response.code;
        fetch("https://www.orotrader.com/api/signUp2.php", {
          method: "POST",
          body: JSON.stringify({
            idnombre: document.getElementById("nombre").value,
            idapellidos: document.getElementById("apellido").value,
            idcorreo: document.getElementById("email").value,
            idphone: document.getElementById("telefono").value,
            idpass: document.getElementById("pass").value,
            country: f_country,
            phonecode: phoneCountryCode
          }),
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.result.result === 1) {
              window.location.href = result.result.data.session_url;
            } else {
              document.getElementById("btnSignUp").value =
                this.props.boot.modales.crear_cuenta;
              document.getElementById("btnSignUp").disabled = false;
              swal({
                icon: "error",
                title: this.props.boot.modales.swal_error_title,
                // text: this.props.boot[result.error],
                text: result.message
              });
            }
          });
      });
  };
  getLogin = (e) => {
    e.preventDefault();
    fetch(getClientZoneApi() + "/insert/AutoLogin", {
      method: "POST",
      body: JSON.stringify({
        user: document.getElementById("tpInput").value,
        pass: document.getElementById("passwordInput").value
      }),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.result === 1) {
          window.location.href = result.data.session_url;
        } else {
          swal({
            icon: "error",
            title: "Sorry something went wrong",
            text: this.state.boot[result.error]
          });
        }
      });
  };
  restorePassword = (e) => {
    if (document.getElementById("tpInput").value !== "") {
      let data = {
        user: document.getElementById("tpInput").value
      };
      fetch(getClientZoneApi() + "/restore/Password", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.result) {
            swal({
              icon: "success",
              title: "Success",
              text: "You will receive an email"
            });
          } else {
            swal({
              icon: "error",
              title: "Error",
              text: this.state.boot[response.error]
            });
          }
        });
    } else {
      swal({
        title: "Error",
        icon: "error",
        text: this.state.boot.modales.swal_error_text
      });
    }
  };
  render() {
    return (
      <>
        <button
          className="botonAmarillo"
          onClick={(e) => {
            this.setState({ abierto: true });
          }}
        >
          {this.state.boot.modales.iniciar}
        </button>
        <Modal isOpen={this.state.abierto} className="contenedorModal">
          <FontAwesomeIcon
            onClick={(e) => {
              this.setState({ abierto: false });
            }}
            icon={faTimes}
            className="iconoCerrar"
          />
          <img src={Logo} alt="Logo" />
          <span>{this.state.boot.modales.iniciar_sesion}</span>
          <form onSubmit={(e) => this.getLogin(e)}>
            <label htmlFor="tpInput">{this.state.boot.modales.usuario}</label>
            <br />
            <input
              name="usuario"
              type="text"
              id="tpInput"
              required
              placeholder={this.state.boot.modales.usuario}
            />
            <br />

            <label htmlFor="passwordInput">
              {this.state.boot.modales.contrasena}
            </label>
            <br />
            <input
              name="contrasena"
              type="password"
              id="passwordInput"
              required
              placeholder={this.state.boot.modales.contrasena}
            />
            <br />

            <button
              type="button"
              className="botonContrasena"
              onClick={() => this.restorePassword()}
            >
              {this.state.boot.modales.olvide_contrasena}
            </button>
            <input
              type="submit"
              value={this.state.boot.modales.boton_iniciar_sesion}
            />
          </form>
          <div>
            <div className="contenedorRegistrarse">
              <p>{this.state.boot.modales.pregunta_registrado}</p>
              <button
                onClick={(e) => {
                  this.setState({ registro_abierto: true });
                }}
                className="botonRegistrarse"
              >
                {this.state.boot.modales.registrarse}
              </button>
            </div>
          </div>
        </Modal>
        <Modal isOpen={this.state.registro_abierto} className="contenedorModal">
          <FontAwesomeIcon
            onClick={(e) => {
              this.setState({ registro_abierto: false });
            }}
            icon={faTimes}
            className="iconoCerrar"
          />
          <img src={Logo} alt="Logo" />

          <span>{this.state.boot.modales.registrarse}</span>
          <form
            className="formularioRegistrarse"
            onSubmit={(e) => this.SignUp(e)}
          >
            <label htmlFor="nombre">{this.state.boot.modales.nombre}</label>
            <br />
            <input
              name="nombre"
              type="text"
              id="nombre"
              required
              placeholder={this.state.boot.modales.nombre}
            />
            <label htmlFor="apellido">{this.state.boot.modales.apellido}</label>
            <br />
            <input
              name="apellido"
              type="text"
              id="apellido"
              required
              placeholder={this.state.boot.modales.apellido}
            />
            <label htmlFor="email">{this.state.boot.modales.correo}</label>
            <br />
            <input
              name="correo"
              type="email"
              id="email"
              required
              placeholder={this.state.boot.modales.correo}
            />
            <label htmlFor="telefono">{this.state.boot.modales.telefono}</label>
            <br />
            <input
              name="telefono"
              type="tel"
              id="telefono"
              required
              placeholder={this.state.boot.modales.telefono}
            />
            <label htmlFor="pais">{this.state.boot.modales.pais}</label>
            <br />
            <select name="pais" id="pais">
              {this.renderPaises()}
            </select>
            <br />
            <label htmlFor="pass">{this.state.boot.modales.contrasena}</label>
            <br />
            <input
              name="contrasena"
              type="password"
              id="pass"
              pattern="^(?=.*[a-zA-Z])(?=\w*[0-9])[^_\W]{6,12}$"
              required
              title={this.state.boot.modales.label_contrasena}
              placeholder={this.state.boot.modales.contrasena}
            />
            <p className="lblFormat">
              {this.state.boot.modales.label_contrasena}
            </p>

            <input
              type="submit"
              className="botonVerde"
              id="btnSignUp"
              value={this.state.boot.modales.crear_cuenta}
            />
          </form>
        </Modal>
      </>
    );
  }
}

export default SignIn;
