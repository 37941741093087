import React, { Component } from "react";
import { getCookie, getBoot } from "../../utils";
import Navigation from "../../components/navigation/navigation.jsx";
import Footer from "../../components/footer/footer.jsx";
import "./legals.css";

export default class Legales extends Component {
  state = {
    boot: getBoot()[getCookie("Orolang")],
    legalEurope: ""
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getUrl();
    this.getLegalEurope();
  }
  getLegalEurope() {
    fetch("https://www.orotrader.com/api/serviceInfo/")
      .then((res) => res.json())
      .then((response) => {
        const legalEurope = response.legal;
        this.setState({ ...this.state, legalEurope });
      });
  }
  getUrl() {
    let actual = window.location + "";
    let split = actual.split("/");
    let respuesta = split[split.length - 1];
    if (respuesta !== "Politicas") {
      split = respuesta.split("Politicas?option=");
      let option = split[split.length - 1];
      if (option !== "") {
        this.showBlock(option);
      }
    }
  }
  showBlock(id) {
    let x = document.querySelectorAll(".bloqueUnico");
    for (let index = 0; index < x.length; index++) {
      x[index].style.display = "none";
    }
    let y = document.getElementById("politicas" + id);
    if (y.style.display === "none" || y.style.display === "") {
      y.style.display = "block";
    } else {
      y.style.display = "none";
    }
  }
  render() {
    return (
      <>
        <Navigation activo="legales" />
        <div className="contenedor">
          <section className="legales">
            <div className="tituloLegales">
              <span>{this.state.boot.politicas.titulo}</span>
            </div>
            <div className="botonesLegales">
              <button onClick={(e) => this.showBlock(0)}>
                {this.state.boot.politicas.pestana1_titulo}
              </button>
              <button onClick={(e) => this.showBlock(1)}>
                {this.state.boot.politicas.pestana2_titulo}
              </button>
              <button onClick={(e) => this.showBlock(2)}>
                {this.state.boot.politicas.pestana3_titulo}
              </button>
              <button onClick={(e) => this.showBlock(3)}>
                {this.state.boot.politicas.pestana4_titulo}
              </button>
              <button onClick={(e) => this.showBlock(4)}>
                {this.state.boot.politicas.pestana5_titulo}
              </button>
              <button onClick={(e) => this.showBlock(5)}>
                {this.state.boot.politicas.pestana6_titulo}
              </button>
              <button onClick={(e) => this.showBlock(6)}>
                {this.state.boot.politicas.pestana7_titulo}
              </button>
            </div>
            <div className="bloquesLegales">
              <div className="bloqueUnico" id="politicas0">
                <span>{this.state.boot.politicas.pestana1_titulo}</span>
                <p>{this.state.boot.politicas.bloque1}</p>
              </div>
              <div className="bloqueUnico" id="politicas1">
                <span>{this.state.boot.politicas.pestana2_titulo}</span>
                <p>{this.state.boot.politicas.bloque2}</p>
                <p>{this.state.boot.politicas.bloque2extra}</p>
                <p>{this.state.boot.politicas.bloque2nuevoextra}</p>
              </div>
              <div className="bloqueUnico" id="politicas2">
                <span>{this.state.boot.politicas.pestana3_titulo}</span>
                <p>{this.state.boot.politicas.bloque3}</p>
                <p>{this.state.boot.politicas.bloque4}</p>
              </div>
              <div className="bloqueUnico" id="politicas3">
                <span>{this.state.boot.politicas.pestana4_titulo}</span>
                <p>{this.state.boot.politicas.bloque5}</p>
              </div>
              <div className="bloqueUnico" id="politicas4">
                <span>{this.state.boot.politicas.pestana5_titulo}</span>
                <p>{this.state.boot.politicas.bloque6}</p>
              </div>
              <div className="bloqueUnico" id="politicas5">
                <span>{this.state.boot.politicas.pestana6_titulo}</span>
                <p>{this.state.boot.politicas.bloque7}</p>
              </div>
              <div className="bloqueUnico" id="politicas6">
                <span>{this.state.boot.politicas.pestana7_titulo}</span>
                <p>{this.state.boot.politicas.bloque8}</p>
              </div>
            </div>
            <div className="bloqueLegaleEurope">
              <br />
              {this.state.legalEurope}
            </div>
          </section>
        </div>
        <Footer />
      </>
    );
  }
}
