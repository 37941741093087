import React, { Component } from "react";
import swal from "sweetalert";
import { getCookie, getBoot } from "../../utils";
import Navigation from "../../components/navigation/navigation.jsx";
import Footer from "../../components/footer/footer.jsx";
import "./webinars.css";
import WebinarsGrid from "../../assets/images/webinar-grid.png";
import WebinarItem1 from "../../assets/images/webinar-1.png";
import WebinarItem2 from "../../assets/images/webinar-2.png";
import WebinarItem3 from "../../assets/images/webinar-3.png";

export default class Webinars extends Component {
  state = {
    boot: getBoot()[getCookie("Orolang")]
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const handleSubmit = e => {
      e.preventDefault();
      const params = {
        name: e.target[0].value,
        email: e.target[1].value,
        interests: e.target[2].value
      };
      fetch("https://www.orotrader.com/api/webinarSignUp.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(params)
      })
        .then(res => res.json())
        .then(response => {
          if (response.result) {
            swal({
              icon: "success",
              title: "Success!",
              text: "The Request has been sent successfuly!"
            }).then(() => {
              document.getElementById("name").focus();
            });
            document.getElementById("name").value = "";
            document.getElementById("email").value = "";
            document.getElementById("interests").value = "";
          } else {
            swal({
              icon: "error",
              title: "Error!",
              text: response.message
            });
          }
        });
    };
    return (
      <>
        <Navigation activo="plataforma" />
        <div className="contenedor">
          <section className="webinars">
            <div className="webinarsInitial">
              <article className="webinarsTitle">
                <h5>{this.state.boot.webinars.newWebinars}</h5>
                <h2>{this.state.boot.webinars.title}</h2>
              </article>
              <article className="webinarsParagraph">
                <p>
                  {this.state.boot.webinars.paragraphOne} <span>Bitcoin </span>
                  {this.state.boot.webinars.but}
                  <span>{this.state.boot.webinars.paragraphOneQuestion}</span>
                </p>
              </article>
            </div>
            <div className="webinarsGrid">
              <article className="webinarsText">
                <p>{this.state.boot.webinars.paragraphTwo}</p>
                <h2>{this.state.boot.webinars.dontMiss}</h2>
                <h4>{this.state.boot.webinars.askInfo}</h4>
              </article>
              <article className="webinarsImage">
                <img src={WebinarsGrid} alt="Webinars" />
              </article>
            </div>
            <div className="itemsTitle">
              <h1>{this.state.boot.webinars.march}</h1>
            </div>
            <div className="webinarsItems">
              <article>
                <img src={WebinarItem1} alt="Webinar" />
                <span>
                  <h5>{this.state.boot.webinars.courseOneTitle}</h5>
                  <p>{this.state.boot.webinars.courseOneP}</p>
                </span>
              </article>
              <article>
                <img src={WebinarItem2} alt="Webinar" />
                <span>
                  <h5>{this.state.boot.webinars.courseTwoTitle}</h5>
                  <p>{this.state.boot.webinars.courseTwoP}</p>
                </span>
              </article>
              <article>
                <img src={WebinarItem3} alt="Webinar" />
                <span>
                  <h5>{this.state.boot.webinars.courseThreeTitle}</h5>
                  <p>{this.state.boot.webinars.courseThreeP}</p>
                </span>
              </article>
            </div>
            <div className="webinarsReach">
              <article></article>
              <form onSubmit={e => handleSubmit(e)}>
                <p>{this.state.boot.webinars.formText}</p>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder={this.state.boot.webinars.name}
                  required
                />
                <input
                  type="text"
                  name="email"
                  id="email"
                  placeholder={this.state.boot.webinars.email}
                  required
                />
                <select
                  id="interests"
                  name="interests"
                  required
                >
                  <option hidden defaultValue value="">
                    {this.state.boot.webinars.select}
                  </option>
                  {[
                    {
                      value:
                        "Criptomonedas: bitcoin, la divisa digital más importante",
                      text:
                        "Criptomonedas: bitcoin, la divisa digital más importante"
                    },
                    {
                      value:
                        "Aprende y conoce todo sobre el mundo de las divisas digitales",
                      text:
                        "Aprende y conoce todo sobre el mundo de las divisas digitales"
                    },
                    {
                      value:
                        "Las mejores criptomonedas para invertir en el 2021",
                      text: "Las mejores criptomonedas para invertir en el 2021"
                    }
                  ].map(w => (
                    <option defaultValue value={w.value}>
                      {w.text}
                    </option>
                  ))}
                </select>
                <article className="buttonContainer">
                  <button className="buttonWebinars" type="submit">
                    Enviar
                  </button>
                </article>
              </form>
            </div>
          </section>
        </div>
        <Footer />
      </>
    );
  }
}
