import React, { Component } from "react";
import { getCookie, getBoot } from "../../utils";
import Navigation from "../../components/navigation/navigation.jsx";
import Footer from "../../components/footer/footer.jsx";
import "./platform.css";
import Laptop from "../../assets/images/laptop.png";
import IconoPunto1 from "../../assets/images/icons/punto1.png";
import IconoPunto2 from "../../assets/images/icons/punto2.png";
import IconoPunto3 from "../../assets/images/icons/punto3.png";
import IconoPunto4 from "../../assets/images/icons/punto4.png";
import IconoPunto5 from "../../assets/images/icons/punto5.png";
import IconoPunto6 from "../../assets/images/icons/punto6.png";
import IconoPunto7 from "../../assets/images/icons/punto7.png";
import IconoPunto8 from "../../assets/images/icons/punto8.png";
import MercadosImagen from "../../assets/images/mercados.png";
import IconoMerito1 from "../../assets/images/icons/merito1.png";
import IconoMerito2 from "../../assets/images/icons/merito2.png";
import IconoMerito3 from "../../assets/images/icons/merito3.png";
import IconoMerito4 from "../../assets/images/icons/merito4.png";

export default class Platform extends Component {
  state = {
    boot: getBoot()[getCookie("Orolang")],
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <Navigation activo="plataforma" />
        <div className="contenedor">
          <section className="encabezado">
            <span>{this.state.boot.plataforma.seccion1_titulo}</span>
            <p>{this.state.boot.plataforma.seccion1_subtitulo}</p>
          </section>
          <section className="portatil">
            <div className="usabilidad">
              <div className="usabilidadImagen">
                <img src={Laptop} alt="Portatil" />
              </div>
              <div className="usabilidadTexto">
                <p>{this.state.boot.plataforma.seccion1_parrafo}</p>
              </div>
            </div>
          </section>
          <section className="negociacion">
            <div className="puntos">
              <div className="punto">
                <img src={IconoPunto1} alt="Punto" />
                <span>{this.state.boot.plataforma.seccion1_punto1}</span>
                <p>{this.state.boot.plataforma.seccion1_punto1_extra}</p>
              </div>
              <div className="punto">
                <img src={IconoPunto2} alt="Punto" />

                <span>{this.state.boot.plataforma.seccion1_punto2}</span>
                <p>{this.state.boot.plataforma.seccion1_punto2_extra}</p>
              </div>
              <div className="punto">
                <img src={IconoPunto3} alt="Punto" />

                <span>{this.state.boot.plataforma.seccion1_punto3}</span>
                <p>{this.state.boot.plataforma.seccion1_punto3_extra}</p>
              </div>
              <div className="punto">
                <img src={IconoPunto4} alt="Punto" />

                <span>{this.state.boot.plataforma.seccion1_punto4}</span>
                <p>{this.state.boot.plataforma.seccion1_punto4_extra}</p>
              </div>
              <div className="punto">
                <img src={IconoPunto5} alt="Punto" />

                <span>{this.state.boot.plataforma.seccion1_punto5}</span>
                <p>{this.state.boot.plataforma.seccion1_punto5_extra}</p>
              </div>
              <div className="punto">
                <img src={IconoPunto6} alt="Punto" />

                <span>{this.state.boot.plataforma.seccion1_punto6}</span>
                <p>{this.state.boot.plataforma.seccion1_punto6_extra}</p>
              </div>
              <div className="punto">
                <img src={IconoPunto7} alt="Punto" />

                <span>{this.state.boot.plataforma.seccion1_punto7}</span>
                <p>{this.state.boot.plataforma.seccion1_punto7_extra}</p>
              </div>
              <div className="punto">
                <img src={IconoPunto8} alt="Punto" />
                <span>{this.state.boot.plataforma.seccion1_punto8}</span>
                <p>{this.state.boot.plataforma.seccion1_punto8_extra}</p>
              </div>
            </div>
          </section>
          <section className="mercados">
            <div className="mercadosInternacionales">
              <div className="mercadosTexto">
                <span>{this.state.boot.plataforma.seccion2_titulo}</span>
                <p>{this.state.boot.plataforma.seccion2_subtitulo}</p>
              </div>
              <div className="mercadosImagen">
                <img src={MercadosImagen} alt="Mercados" />
              </div>
            </div>
          </section>
          <section className="meritos">
            <div className="meritosOro">
              <div className="merito">
                <img src={IconoMerito1} alt="Merito 1" />
                <span>
                  {this.state.boot.plataforma.seccion2_cuadro1_titulo}
                </span>
                <p>{this.state.boot.plataforma.seccion2_cuadro1_parrafo}</p>
              </div>
              <div className="merito">
                <img src={IconoMerito2} alt="Merito 2" />
                <span>
                  {this.state.boot.plataforma.seccion2_cuadro2_titulo}
                </span>
                <p>{this.state.boot.plataforma.seccion2_cuadro2_parrafo}</p>
              </div>
              <div className="merito">
                <img src={IconoMerito3} alt="Merito 3" />

                <span>
                  {this.state.boot.plataforma.seccion2_cuadro3_titulo}
                </span>
                <p>{this.state.boot.plataforma.seccion2_cuadro3_parrafo}</p>
              </div>
              <div className="merito">
                <img src={IconoMerito4} alt="Merito 4" />
                <span>
                  {this.state.boot.plataforma.seccion2_cuadro4_titulo}
                </span>
                <p>{this.state.boot.plataforma.seccion2_cuadro4_parrafo}</p>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </>
    );
  }
}
