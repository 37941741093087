import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App.jsx";


ReactDOM.render(
    <App />,
  document.getElementById("root")
);

