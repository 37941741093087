import React, { Component } from "react";
import { getCookie, getBoot } from "../../utils";
import Navigation from "../../components/navigation/navigation.jsx";
import Footer from "../../components/footer/footer.jsx";
import ImagenAcademia from "../../assets/images/Academia2.png";
import "./academics.css";
import IconoNoticias from "../../assets/images/icons/Noticias.png";
import IconoGlosario from "../../assets/images/icons/Vocabulario.png";
import IconoEbooks from "../../assets/images/icons/ebook.png";
import IconoCalendario from "../../assets/images/icons/calendario.png";

export default class Academics extends Component {
  state = {
    boot: getBoot()[getCookie("Orolang")],
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <Navigation activo="academia" />
        <div className="contenedor">
          <section className="bienvenida">
            <div className="centroEducativo">
              <div className="imagenBienvenida">
                <img src={ImagenAcademia} alt="Academia" />
              </div>
              <div className="textoBienvenida">
                <span>{this.state.boot.academia.seccion1_titulo}</span>
                <p>{this.state.boot.academia.seccion1_subtitulo}</p>
                <button>
                  <a
                    href="https://academics.orotrader.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.state.boot.academia.seccion1_boton}
                  </a>
                </button>
              </div>
            </div>
          </section>
          <section className="beneficios">
            <div className="beneficiosUnicos">
              <div className="beneficio">
                <img src={IconoNoticias} alt="Noticias" />
                <span>{this.state.boot.academia.beneficio1_titulo}</span>
                <p>{this.state.boot.academia.beneficio1_descripcion}</p>
              </div>
              <div className="beneficio">
                <img src={IconoGlosario} alt="Glosario" />
                <span>{this.state.boot.academia.beneficio2_titulo}</span>
                <p>{this.state.boot.academia.beneficio2_descripcion}</p>
              </div>
              <div className="beneficio">
                <img src={IconoEbooks} alt="Ebooks" />

                <span>{this.state.boot.academia.beneficio3_titulo}</span>
                <p>{this.state.boot.academia.beneficio3_descripcion}</p>
              </div>
              <div className="beneficio">
                <img src={IconoCalendario} alt="Calendario" />
                <span>{this.state.boot.academia.beneficio4_titulo}</span>
                <p>{this.state.boot.academia.beneficio4_descripcion}</p>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </>
    );
  }
}
