import React from "react";
import "./robots.scss";
import { getCookie, getBoot } from "../../utils";
import Navigation from "../../components/navigation/navigation.jsx";
import Footer from "../../components/footer/footer.jsx";
import Laptop from "../../assets/images/computer.png";
import Stadistics from "../../assets/images/orobot-image.png";
import SmartImg from "../../assets/images/smart_img.png";
import Robot1 from "../../assets/images/robot1.png";
import Robot2 from "../../assets/images/robot2.png";
import Robot3 from "../../assets/images/robot3.png";
import PerformanceIcon from "../../assets/images/icons/rendimiento.svg";
import LowRiskIcon from "../../assets/images/icons/riesgo.svg";
import BrokersIcon from "../../assets/images/icons/corredores.svg";
import AutoTraderIcon from "../../assets/images/icons/autotrader.svg";
import DownloadInstallIcon from "../../assets/images/descarga.png";
import VideoInstallIcon from "../../assets/images/video.png";
import PresetsInstallIcon from "../../assets/images/presets.png";
import Marketwatch from "../../assets/images/marketwatch.png";
import Quotes from "../../assets/images/comillas.png";

export default function Robots() {
  const boot = getBoot()[getCookie("Orolang")];
  return (
    <div>
      <Navigation />
      <div className="contenedor orobotContainer">
        <section className="hero">
          <h1 className="hero__title">{boot.robots.title}</h1>
          <h1 className="hero__subtitle">{boot.robots.subtitle}</h1>
          <div className="hero__box">
            <button className="hero__button">{boot.robots.learn}</button>
            <p className="hero__millions">{boot.robots.millions}</p>
          </div>
        </section>
        <section className="approach">
          <div className="approach__box">
            <h1 className="approach__title">{boot.robots.approach}</h1>
            <p className="approach__paragraph">
              {boot.robots.approachParagraph}
            </p>
          </div>
          <div className="approach__box">
            <img src={Laptop} alt="Laptop" className="approach__image" />
          </div>
        </section>
        <section className="orobot">
          <div className="orobot__box">
            <img src={Stadistics} alt="" className="orobot__image" />
          </div>
          <div className="orobot__box">
            <h2 className="orobot__title">{boot.robots.orobot}</h2>
            <ul className="orobot__list">
              <li className="orobot__item">{boot.robots.orobotOne}</li>
              <li className="orobot__item">{boot.robots.orobotTwo}</li>
              <li className="orobot__item">{boot.robots.orobotThree}</li>
            </ul>
          </div>
        </section>
        <section className="smart">
          <div className="smart__box">
            <h2 className="smart__title">{boot.robots.smartRobot}</h2>
            <p className="smart__description">{boot.robots.downloadRobot}</p>
          </div>
          <div className="smart__box">
            <img src={SmartImg} alt="" className="smart__image" />
          </div>
        </section>
        <section className="accuracy">
          <p className="accuracy__left">{boot.robots.accuracy}</p>
          <p className="accuracy__right">{boot.robots.accuracyParagraph}</p>
        </section>
        <section className="table">
          <div className="table__container">
            <div className="table__header">
              <h2 className="table__title">Capital</h2>
              <h2 className="table__title">Currency Pairs</h2>
              <h2 className="table__title">Monthly P&L</h2>
            </div>
            <div className="table__content">
              <p className="table__col">$500</p>
              <p className="table__col">3</p>
              <p className="table__col">$175</p>
              <p className="table__col">$1000</p>
              <p className="table__col">7</p>
              <p className="table__col">$380</p>
              <p className="table__col">$5000</p>
              <p className="table__col">9</p>
              <p className="table__col">$2,100</p>
            </div>
          </div>
        </section>
        <section className="icons">
          <div className="icons__item">
            <img src={PerformanceIcon} alt="" className="icons__icon"/>
            <p className="icons__text">{boot.robots.accuracyOne}</p>
          </div>
          <div className="icons__item">
            <img src={LowRiskIcon} alt="R" className="icons__icon"/>
            <p className="icons__text">{boot.robots.accuracyTwo}</p>
          </div>
          <div className="icons__item">
            <img src={BrokersIcon} alt="" className="icons__icon"/>
            <p className="icons__text">{boot.robots.accuracyThree}</p>
          </div>
          <div className="icons__item">
            <img src={AutoTraderIcon} alt="" className="icons__icon"/>
            <p className="icons__text">Auto trader</p>
          </div>
        </section>
        <section className="install">
          <h2 className="install__title">{boot.robots.installation}</h2>
          <div className="install__content">
            <div className="install__item">
              <img src={DownloadInstallIcon} alt="" className="install__item-image"/>
              <div className="install__item-content">
                <h3 className="install__item-title">{boot.robots.installationOne}</h3>
                <p className="install__item-text">{boot.robots.installationOneP}</p>
              </div>
            </div>
            <div className="install__item">
              <img src={VideoInstallIcon} alt="" className="install__item-image"/>
              <div className="install__item-content">
                <h3 className="install__item-title">{boot.robots.installationTwo}</h3>
                <p className="install__item-text">{boot.robots.installationTwoP}</p>
              </div>
            </div>
            <div className="install__item">
              <img src={PresetsInstallIcon} alt="" className="install__item-image"/>
              <div className="install__item-content">
                <h3 className="install__item-title">{boot.robots.installationThree}</h3>
                <p className="install__item-text">{boot.robots.installationThreeP}</p>
              </div>
            </div>
          </div>
        </section>
        <section className="list">
          <article className="list__robot">
            <div className="list__box">
              <img src={Robot1} alt="Robot" className="list__image" />
            </div>
            <div className="list__box list__box--text">
              <h3 className="list__name">BigExpert</h3>
              <p className="list__paragraph">{boot.robots.robotOne}</p>
              <p className="list__price">$1000 USD</p>
            </div>
          </article>
          <article className="list__robot">
            <div className="list__box">
              <img src={Robot2} alt="Robot" className="list__image" />
            </div>
            <div className="list__box list__box--text">
              <h3 className="list__name">Advanced Scalper</h3>
              <p className="list__paragraph">{boot.robots.robotTwo}</p>
              <p className="list__price">$2500 USD</p>
            </div>
          </article>
          <article className="list__robot">
            <div className="list__box">
              <img src={Robot3} alt="Robot" className="list__image" />
            </div>
            <div className="list__box list__box--text">
              <h3 className="list__name">Cairo MT4 </h3>
              <p className="list__paragraph">{boot.robots.robotThree}</p>
              <p className="list__price">$5000 USD</p>
            </div>
          </article>
        </section>
        <section className="marketwatch">
          <img src={Marketwatch} alt="" className="marketwatch__image" />
          <p className="marketwatch__paragraph">
            <img src={Quotes} alt="" className="marketwatch__quotes" />
            <span className="marketwatch__bold">OroBot X</span>{" "}
            {boot.robots.marketwatch}
          </p>
          <p className="marketwatch__disclaimer">
            El trading conlleva un alto nivel de riesgo y puede no ser adecuado
            para todos los inversores. El alto grado de apalancamiento puede
            funcionar tanto en tu contra como a tu favor. Antes de decidir
            operar con divisas, acciones, metales, criptomonedas, fondos,
            materia prima y criptodivisas, debes considerar cuidadosamente tus
            objetivos de inversión, nivel de experiencia y apetito de riesgo.
            Los servicios financieros proporcionados por Orotrader conllevan un
            alto nivel de riesgo y existe la posibilidad de que puedas perder
            parte o la totalidad de tu inversión inicial, por lo tanto, no debes
            invertir el dinero que no puedas permitirte perder. Debes ser
            consciente de todos los riesgos asociados con el trading de divisas,
            binarias, criptos, acciones, fondos, materia prima entre otros, y
            buscar consejo de un asesor financiero acorde a tus necesidades.
            Comprenda que los riesgos son solo suyos, depende de usted evaluar
            si sus recursos financieros son adecuados y el nivel de riesgo que
            asume. Cualquier opinión, noticia, investigación, análisis, precios
            u otra información contenida en este sitio web se proporciona como
            comentario general del mercado, y no debe ser considerada un consejo
            de inversión. Orotrader no aceptará responsabilidad alguna por
            cualquier pérdida o daño, o cuando utilice Internet y otras
            instalaciones de comunicación de la red, uso de servicios como mt4 y
            mt5 o cualquier otro dispositivo electrónico, entre otros. Usted
            será responsable de cualquier pérdida de beneficios, que pueda
            surgir directa o indirectamente del uso o confianza en dicha
            información.
          </p>
        </section>
      </div>
      <Footer />
    </div>
  );
}
