import React, { Component } from "react";
import { getCookie, getBoot } from "../../utils";
import Navigation from "../../components/navigation/navigation.jsx";
import Footer from "../../components/footer/footer.jsx";
import "./about.css";
import IconoMision1 from "../../assets/images/icons/mision1.png";
import IconoMision2 from "../../assets/images/icons/mision2.png";
import IconoMision3 from "../../assets/images/icons/mision3.png";
import IconoValor from "../../assets/images/icons/valor.png";
import ImagenTrading from "../../assets/images/Trading.png";
import IconoTel from "../../assets/images/icons/tel.png";
import IconoMail from "../../assets/images/icons/mail.png";
import swal from "sweetalert";

export default class About extends Component {
  state = {
    boot: getBoot()[getCookie("Orolang")],
  };
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  sendEmail = (e) => {
    e.preventDefault();
    let message = {};
    message["nombre"] = document.getElementById("nombre").value;
    message["email"] = document.getElementById("email").value;
    message["mensaje"] = document.getElementById("mensaje").value;
    message["subject"] = message["nombre"] + " a enviado un mensaje.";
    message["action"] = "sendContactMail";

    fetch("https://www.orotrader.com/api/sendEmail.php", {
      method: "POST",
      body: JSON.stringify(message),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.result === 1) {
          swal({
            icon: "success",
            title: this.state.boot.acerca.swal_success_title,
            text: this.state.boot.acerca.swal_text,
          });
        } else {
          swal({
            icon: "error",
            title: "Oops",
            text: result.error,
          });
        }
      });
  };

  render() {
    return (
      <>
        <Navigation activo="acerca" />
        <div className="contenedor">
          <section className="nosotros">
            <span>{this.state.boot.acerca.seccion1_titulo}</span>
            <div className="datos">
              <div className="dato">
                <img src={IconoMision1} alt="Mision" />
                <span>
                  {this.state.boot.acerca.seccion1_contenedor1_titulo}
                </span>
                <p>{this.state.boot.acerca.seccion1_contenedor1_descripcion}</p>
              </div>
              <div className="dato">
                <img src={IconoMision2} alt="Mision" />

                <span>
                  {this.state.boot.acerca.seccion1_contenedor2_titulo}
                </span>
                <p>{this.state.boot.acerca.seccion1_contenedor2_descripcion}</p>
              </div>
              <div className="dato">
                <img src={IconoMision3} alt="Mision" />
                <span>
                  {this.state.boot.acerca.seccion1_contenedor3_titulo}
                </span>
                <p>{this.state.boot.acerca.seccion1_contenedor3_descripcion}</p>
              </div>
            </div>
          </section>
          <section className="comunidad">
            <div className="comerciantes">
              <div className="comerciantesAmarillo">
                <span>{this.state.boot.acerca.seccion2_titulo}</span>
              </div>
              <div className="comerciantesBlanco">
                <span>{this.state.boot.acerca.seccion2_descripcion}</span>
              </div>
            </div>
          </section>
          <section className="valores">
            <div className="valoresUnicos">
              <div className="valorUnico">
                <img src={IconoValor} alt="Valor" />
                <span>{this.state.boot.acerca.seccion2_punto1}</span>
              </div>
              <div className="valorUnico">
                <img src={IconoValor} alt="Valor" />
                <span>{this.state.boot.acerca.seccion2_punto2}</span>
              </div>
              <div className="valorUnico">
                <img src={IconoValor} alt="Valor" />
                <span>{this.state.boot.acerca.seccion2_punto3}</span>
              </div>
              <div className="valorUnico">
                <img src={IconoValor} alt="Valor" />
                <span>{this.state.boot.acerca.seccion2_punto4}</span>
              </div>
              <div className="valorUnico">
                <img src={IconoValor} alt="Valor" />
                <span>{this.state.boot.acerca.seccion2_punto5}</span>
              </div>
            </div>
          </section>
          <section className="centro">
            <div className="centroTrading">
              <div className="tradingImagen">
                <img src={ImagenTrading} alt="Trading" />
              </div>
              <div className="tradingTexto">
                <span>{this.state.boot.acerca.seccion3_titulo}</span>
                <p>{this.state.boot.acerca.seccion3_parrafo1}</p>
                <p>{this.state.boot.acerca.seccion3_parrafo2}</p>
                <p>{this.state.boot.acerca.seccion3_parrafo3}</p>
              </div>
            </div>
          </section>
          <section className="linea">
            <div className="lineaFinanciera">
              <div className="lineaUnica">
                <span className="circuloUnico"></span>
                <p>{this.state.boot.acerca.seccion3_punto1}</p>
              </div>
              <div className="lineaUnica">
                <span className="circuloUnico"></span>
                <p>{this.state.boot.acerca.seccion3_punto2}</p>
              </div>
              <div className="lineaUnica">
                <span className="circuloUnico"></span>
                <p>{this.state.boot.acerca.seccion3_punto3}</p>
              </div>
              <div className="lineaUnica">
                <span className="circuloUnico"></span>
                <p>{this.state.boot.acerca.seccion3_punto4}</p>
              </div>
              <div className="lineaUnica">
                <span className="circuloUnico"></span>
                <p>{this.state.boot.acerca.seccion3_punto5}</p>
              </div>
              <div className="lineaUnica">
                <span className="circuloUnico"></span>
                <p>{this.state.boot.acerca.seccion3_punto6}</p>
              </div>
            </div>
          </section>
          <section className="contactanos">
            <div className="contactaNosotros">
              <span>{this.state.boot.acerca.seccion4_titulo}</span>
            </div>
            <div className="contacta">
              <div className="contactaTexto">
                <p>{this.state.boot.acerca.seccion4_parrafo1}</p>
                <p>{this.state.boot.acerca.seccion4_parrafo2}</p>
                <p>{this.state.boot.acerca.seccion4_parrafo3}</p>
                <p>
                  <img src={IconoTel} alt="Tel" /> +447537185120
                </p>
                <p>
                  <img src={IconoMail} alt="Mail" />
                  support@orotrader.com, docs@orotrader.com
                </p>
              </div>
              <div className="contactaFormulario">
                <span>{this.state.boot.acerca.seccion4_parrafo4}</span>

                <form onSubmit={(e) => this.sendEmail(e)}>
                  <input
                    type="text"
                    id="nombre"
                    placeholder={this.state.boot.modales.nombre}
                  />
                  <input
                    type="email"
                    id="email"
                    placeholder={this.state.boot.modales.correo}
                  />
                  <textarea
                    type="text"
                    id="mensaje"
                    placeholder={this.state.boot.acerca.seccion4_input3}
                  />
                  <input
                    type="submit"
                    placeholder={this.state.boot.acerca.seccion4_input4}
                  />
                </form>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </>
    );
  }
}
