import React, { Component } from "react";
import { getCookie, getBoot } from "../../utils";
import { Link } from "react-router-dom";
import "./footer.css";
import LogoBlanco from "../../assets/images/LogoBlanco.png";
import IconoFB from "../../assets/images/icons/facebook.svg";
import IconoIG from "../../assets/images/icons/instagram.svg";
import IconoTW from "../../assets/images/icons/twitter.svg";
import imgBanner1 from "../../assets/images/icons/asset6.png";
import imgBanner2 from "../../assets/images/icons/asset7.png";
import imgBanner3 from "../../assets/images/icons/asset8.png";
import imgBanner4 from "../../assets/images/icons/asset9.png";
import imgBanner5 from "../../assets/images/icons/asset10.png";
import imgBanner6 from "../../assets/images/icons/asset11.png";
import imgBanner7 from "../../assets/images/icons/asset12.png";
import imgBanner8 from "../../assets/images/icons/asset13.png";
import imgBanner9 from "../../assets/images/icons/asset14.png";
import imgBanner10 from "../../assets/images/icons/asset15.png";
import imgBanner11 from "../../assets/images/icons/asset16.png";
import imgBanner12 from "../../assets/images/icons/asset17.png";
import imgBanner13 from "../../assets/images/icons/asset18.png";
import imgBanner14 from "../../assets/images/icons/asset19.png";
import imgBanner15 from "../../assets/images/icons/asset20.png";

export default class Footer extends Component {
  state = {
    boot: getBoot()[getCookie("Orolang")],
    continent: ""
  };

  componentDidMount() {
    fetch("https://www.orotrader.com/api/serviceInfo/")
      .then((res) => res.json())
      .then((response) => {
        const continent = response.data;
        this.setState({ ...this.state, continent });
      });
  }

  render() {
    return (
      <>
        <div className="contenedor">
          <div className="contenedorPagos">
            <div>
              <img src={imgBanner1} alt="Metodo de pago" />
            </div>
            <div>
              <img src={imgBanner2} alt="Metodo de pago" />
            </div>
            <div>
              <img src={imgBanner3} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner4} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner5} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner6} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner7} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner8} alt="Metodo de pago" />
            </div>
            <div>
              <img src={imgBanner9} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner10} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner11} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner12} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner13} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner14} alt="Metodo de pago" />
            </div>
            <div>
              {" "}
              <img src={imgBanner15} alt="Metodo de pago" />
            </div>
          </div>

          <footer>
            <div className="redesSociales">
              <img className="logoFooter" src={LogoBlanco} alt="Logo" />
              <div className="logosSociales">
                <a
                  href="https://www.facebook.com/Orotrader/?ref=page_internal"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconoFB} alt="Facebook" />
                </a>
                <a
                  href="https://www.instagram.com/orotrader_1/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconoIG} alt="Instagram" />
                </a>
                <a
                  href="https://twitter.com/Orotrader1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconoTW} alt="Twitter" />
                </a>
              </div>
            </div>
            <div className="enlacesPrivacidad">
              <div className="cuentasFooter">
                <span>{this.state.boot.footer.titulo1}</span>
                <ul>
                  <li>
                    <Link to="/">{this.state.boot.footer.link2}</Link>
                  </li>
                </ul>
              </div>
              <div className="acercaFooter">
                <span>{this.state.boot.footer.titulo2}</span>
                <ul>
                  <li>
                    <Link to="/Acerca">{this.state.boot.footer.link3}</Link>
                  </li>
                </ul>
              </div>
              <div className="plataformaFooter">
                <b>
                  <span>{this.state.boot.footer.titulo3}</span>
                </b>
                <ul>
                  <li>Sirix</li>
                </ul>
              </div>
              <div className="legalesFooter">
                <span>{this.state.boot.footer.titulo6}</span>
                <ul>
                  <li>
                    <Link to="/Politicas?option=0">
                      {this.state.boot.footer.link5}
                    </Link>
                  </li>
                  <li>
                    <Link to="/Politicas?option=0">
                      {this.state.boot.footer.link6}
                    </Link>
                  </li>
                  <li>
                    <Link to="/Politicas?option=1">
                      {this.state.boot.footer.link7}
                    </Link>
                  </li>
                  <li>
                    <Link to="/Politicas?option=2">
                      {this.state.boot.footer.link8}
                    </Link>
                  </li>
                  <li>
                    <Link to="/Politicas?option=3">
                      {this.state.boot.footer.link9}
                    </Link>
                  </li>
                  <li>
                    <Link to="/Politicas?option=4">AML</Link>
                  </li>
                  <li>
                    <Link to="/Politicas?option=5">KYC</Link>
                  </li>
                  <li>
                    <Link to="/Politicas?option=6">
                      {this.state.boot.politicas.pestana7_titulo}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </footer>
          <div style={{ color: "gray", fontSize: "10px" }}>
            <p>{this.state.continent}</p>
          </div>
        </div>
      </>
    );
  }
}
