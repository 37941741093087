import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carrusel.css";
import Login from "../modals/Login.jsx";

export default class CenterMode extends Component {
  componentDidMount() {}
  render() {
    const settings = {
      // className: "center",
      // centerMode: true,
      infinite: true,
      // centerPadding: "10px",
      slidesToShow: 3,
      slidesToScroll: 1,
      speed: 1000,

      dots: true,
      // cssEase: "linear",
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1150,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
      ],
    };
    return (
      <div>
        <Slider {...settings}>
          <div className="tipoCuenta">
            <span>{this.props.idioma.cuenta1_titulo}</span>
            <ul>
              <li>
                <p>{this.props.idioma.cuenta1_beneficio1}</p>
              </li>
              <li>
                <p>{this.props.idioma.cuenta1_beneficio2}</p>
              </li>
              <li>
                <p>{this.props.idioma.cuenta1_beneficio3}</p>
              </li>
              <li>
                <p>{this.props.idioma.cuenta1_beneficio4}</p>
              </li>
              <li>
                <p>{this.props.idioma.cuenta1_beneficio5}</p>
              </li>
            </ul>
            <Login />
          </div>
          <div className="tipoCuenta">
            <span>{this.props.idioma.cuenta2_titulo}</span>

            <ul>
              <li>
                <p>{this.props.idioma.cuenta2_beneficio1}</p>
              </li>
              <li>
                <p>{this.props.idioma.cuenta2_beneficio2}</p>
              </li>
              <li>
                <p>{this.props.idioma.cuenta2_beneficio3}</p>
              </li>
              <li>
                <p>{this.props.idioma.cuenta2_beneficio4}</p>
              </li>
              <li>
                <p>{this.props.idioma.cuenta2_beneficio5}</p>
              </li>
            </ul>
            <Login />
          </div>
          <div className="tipoCuenta">
            <span>{this.props.idioma.cuenta3_titulo}</span>

            <ul>
              <li>
                <p>{this.props.idioma.cuenta3_beneficio1}</p>
              </li>
              <li>
                <p>{this.props.idioma.cuenta3_beneficio2}</p>
              </li>
              <li>
                <p>{this.props.idioma.cuenta3_beneficio3}</p>
              </li>
              <li>
                <p>{this.props.idioma.cuenta3_beneficio4}</p>
              </li>
              <li>
                <p>{this.props.idioma.cuenta3_beneficio5}</p>
              </li>
            </ul>
            <Login />
          </div>
        </Slider>
      </div>
    );
  }
}
