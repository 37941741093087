import React, { Component } from "react";
import Navigation from "../../components/navigation/navigation.jsx";
import Footer from "../../components/footer/footer.jsx";
import { getCookie, getBoot } from "../../utils";
import { Link } from "react-router-dom";
import ImagenEntrenamiento from "../../assets/images/icons/entrenamiento.png";
import ImagenTecnologia from "../../assets/images/icons/tecnologia.png";
import ImagenSeguridad from "../../assets/images/icons/seguridad.png";
import CarruselCuentas from "../../components/carrusel/carrusel.jsx";

import "./home.css";

class Home extends Component {
  state = {
    boot: getBoot()[getCookie("Orolang")],
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    var vid = document.getElementById("myVideo");
    vid.controls = false;
    vid.playbackRate = 0.5;
  }
  render() {
    return (
      <>
        <Navigation />
        <div className="contenedor">
          <section className="presentacion">
            <video autoPlay playsInline muted loop id="myVideo">
              <source src="video/principal.mp4" type="video/mp4" />
            </video>
            <div className="contenedorTextoInicial">
              <div className="textoInicial">
                <span>{this.state.boot.home.seccion1_titulo}</span>
                <span>{this.state.boot.home.seccion1_subtitulo}</span>
                <div className="parrafosTextoInicial">
                  <p>{this.state.boot.home.seccion1_parrafo}</p>
                  <p>{this.state.boot.home.seccion1_parrafo2}</p>
                  <p>{this.state.boot.home.seccion1_parrafo3}</p>
                  <p>{this.state.boot.home.seccion1_parrafo4}</p>
                </div>
                <button className="botonAmarillo">
                  <Link to="/Academia">
                    {this.state.boot.home.seccion1_boton}
                  </Link>
                </button>
              </div>
            </div>
          </section>
          <section className="grafico">
            <div className="textoGrafico">
              <p> {this.state.boot.home.seccion2_titulo}</p>
            </div>
            <div className="graficoTV">
              <iframe
                id="tradingView"
                title="TradingView"
                className="iframeTV"
                src="https://s.tradingview.com/mediumwidgetembed/?symbols=BTCUSD,ETHUSD,XRPUSDT,EURUSD,EURCHF,GDP&BTCUSD=COINBASE%3ABTCUSD%7C1d&ETHUSD=BITFINEX%3AETHUSD%7C1d&XRPUSDT=BINANCE%3AXRPUSDT%7C1d&EURUSD=OANDA%3AEURUSD%7C1d&EURCHF=OANDA%3AEURCHF%7C1d&GDP=FRED%3AGDP%7C1d&locale=en&trendLineColor=%234bafe9&underLineColor=%23dbeffb&fontColor=%2383888D&gridLineColor=%23e9e9ea&width=900px&height=400px&colorTheme=undefined&utm_source=2mercados.com&utm_medium=widget&utm_campaign=symbol-overview%22"
              ></iframe>
            </div>
          </section>
          <section className="pasos">
            <div className="textoPasos">
              <p>{this.state.boot.home.seccion3_titulo}</p>
            </div>
            <div className="pasosDescripcion">
              <div className="paso">
                <span>{this.state.boot.home.seccion3_cuadro1_titulo}</span>
                <p>{this.state.boot.home.seccion3_cuadro1_subtitulo}</p>
              </div>
              <div className="paso">
                <span>{this.state.boot.home.seccion3_cuadro2_titulo}</span>
                <p>{this.state.boot.home.seccion3_cuadro2_subtitulo}</p>
              </div>
              <div className="paso">
                <span>{this.state.boot.home.seccion3_cuadro3_titulo}</span>
                <p>{this.state.boot.home.seccion3_cuadro3_subtitulo}</p>
              </div>
              <div className="paso">
                <span>{this.state.boot.home.seccion3_cuadro4_titulo}</span>
                <p>{this.state.boot.home.seccion3_cuadro4_subtitulo}</p>
              </div>
            </div>
          </section>
          <section className="ventajas">
            <div className="textoVentajas">
              <p>{this.state.boot.home.seccion4_titulo}</p>
            </div>
            <div className="ventajasUnicas">
              <div className="ventaja">
                <img src={ImagenSeguridad} alt="Seguridad" />
                <span> {this.state.boot.home.seccion4_cuadro1_titulo}</span>
                <p> {this.state.boot.home.seccion4_cuadro1_subtitulo}</p>
              </div>
              <div className="ventaja">
                <img src={ImagenTecnologia} alt="Tecnologia" />
                <span> {this.state.boot.home.seccion4_cuadro2_titulo}</span>
                <p> {this.state.boot.home.seccion4_cuadro2_subtitulo}</p>
              </div>
              <div className="ventaja">
                <img src={ImagenEntrenamiento} alt="Entrenamiento" />
                <span> {this.state.boot.home.seccion4_cuadro3_titulo}</span>
                <p> {this.state.boot.home.seccion4_cuadro3_subtitulo}</p>
              </div>
            </div>
          </section>
          <section className="cuentas">
            <span>{this.state.boot.home.seccion5_titulo}</span>
            <div className="carruselCuentas">
              <CarruselCuentas idioma={this.state.boot.home.tarjetas} />
            </div>
          </section>
        </div>
        <Footer />
      </>
    );
  }
}

export default Home;
